import logo from "assets/images/brands/Neolin_lg.png";
import logoSm from "assets/images/brands/Neolin_sm.png";

export const clientName = "Neolin";
export const developedBy = "Neolin";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Neolin";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
export const smLogo = logoSm;
